import jwt_decode from "jwt-decode";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

import { WalletEntryPosition } from "@particle-network/auth";
import { SolanaWallet } from "@particle-network/solana-wallet";
import {
  Ethereum,
  EthereumGoerli,
  Solana,
} from "@particle-network/chains";
import { ParticleProvider } from "@particle-network/provider";
import {
  evmWallets,
  solanaWallets,
  ParticleConnect,
} from "@particle-network/connect";
import { ParticleNetwork, UserInfo } from "@particle-network/auth";
import "@particle-network/connect-react-ui/dist/index.css";

import video1 from './/media/video.mp4';
import poster1 from './/media/bloomverseteaser.webp';
import logo from './/media/bloomlogo.png';

import upgradingImg from './/media/upgrading.png';
import discord from './/media/discor-btn.png';

import {
  Keypair,
  Connection,
  Transaction,
  TransactionInstruction,
  SystemProgram,
  clusterApiUrl,
  PublicKey,
  sendAndConfirmTransaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";

import {
  transfer,
  getMint,
  getOrCreateAssociatedTokenAccount,
  createTransferInstruction,
} from "@solana/spl-token";

import styles from "./app.module.css";

import { Grid, Box } from '@mui/material';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}




//const root = ReactDOM.createRoot(document.getElementById('root') );

const Play = (props) => {
  const web3 = require("@solana/web3.js");

  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  //const user = useContext(UserContext);
  const { classes } = props;
  var unityGame;
  //window.unityGame = useUnityContext;
  window.rpmFrame = document.getElementById("rpm-frame");

  window.container = document.querySelector("#unity-container");
  window.canvas = document.querySelector("#unity-canvas");
  window.loadingBar = document.querySelector("#unity-loading-bar");
  window.progressBarFull = document.querySelector("#unity-progress-bar-full");
  window.fullscreenButton = document.querySelector("#unity-fullscreen-button");
  window.warningBanner = document.querySelector("#unity-warning");
  window.rpmHideButton = document.getElementById("rpm-hide-button");
  window.canvasWrapper = document.getElementById("canvas-wrap");

  let [playerModal,setPlayerModal] = useState(false);
  let [playerVideo,setPlayerVideo] = useState("-Arp51Hk8X8");

  const [publicKey, setPublicKey] = useState();
  const [providerW, setProvider] = useState("");


  let anim1Ref = React.createRef();
 
  //const [walletParticle, setWalletParticle] = useState(null);

  const walletParticle = React.useRef(null);

  const [RPMready, setRPMready] = useState(false);
  const [first, setFirst] = useState(false);
  const currentTip = useRef(0);
  const [tipInterval, setTipInterval] = useState(null);

  const [solanaKey,setSolanaKey] = useState("");

  const [maintenance, setMantainance] = useState(true);
 

  const [backgroundR, setBackground] = useState("GameLoadingScreen.jpg");

  const [setsrc, setSrc] = useState("");
  const [iframedisplay, setIframeDisplay] = useState("none");

  /*const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );*/

  const animtease = () => {
    //setAnim1(true);
     setPlayerModal(true);
     setPlayerVideo("usOEI1yMS10")
  }
  

  function initTransak() {
    
    initParticleCore(true,true);
/*
    const transak = new transakSDK({
      apiKey: '89797e45-519a-4a18-962e-0f069c166b45',  //'89797e45-519a-4a18-962e-0f069c166b45',
      environment: 'STAGING', // STAGING/PRODUCTION (Required)
      walletAddress: '',
      themeColor: '59A662',
      email: '',
      //hostURL: window.location.origin,
      //walletAddress : '',
      widgetHeight: '550px',
      widgetWidth: '560px',
      defaultNetwork: 'sol',
      //defaultCryptoCurrency: 'sol',
      hideMenu : true,
      networks: 'solana,ethereum', // NETWORK PREFFERENCES
      cryptoCurrencyList:
        'eth,sol',
      defaultCryptoAmount: 1,
    });

    transak.init();

    console.log("init transka")

    transak.on(transak.ALL_EVENTS, (data) => {
  
    });
    
    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {

      console.log("closing state");
      transak.close();
    });
    
    // This will trigger when the user marks payment is made
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      transak.close();
    });

    */

  }

  
function _takeScreenshot(){
  const dataUrl = takeScreenshot("image/jpg", .9);
  console.log(dataUrl)
  const linkSource = dataUrl;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = "bloomverseChest.png";
  downloadLink.click();
}
  

  //var connectKit = null;


  //const { theme, setTheme } = useParticleTheme();
  //const { language, changLanguage } = useLanguage();
  //const connectModal = useConnectModal();
  //const provider2 = useParticleProvider();

  //const { isSwtichChain, renderChains } = useSwitchChains();

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;
      //   const provider = window.particle?.solana;

      //console.log(provider,"provider")
      //return  prov;
      if (provider?.isPhantom) {
        //console.log("Habemus phantom");

        return provider;
      }
    }
  };

  const getProviderParticle = async () => {
    return ""; // new SolanaWallet(provider.auth);
  };

  const 
  initParticleCore = async (invokeSuscribe,openBuy) => {
    //console.log(
    //  "starting particle core---------------------------------------------"
   // );
    var particle = new ParticleNetwork({
      projectId: "728abe3c-03d6-48e8-a322-c58e0f57dfd7",
      clientKey: "cFw2Gvfv24oXH6ka5Y4JU7crp9Fmuv46fcqvHd9k",
      appId: "bcf1f843-891b-4e66-a1b0-3c367f6314a3",
      chainName: Solana.name,
      chainId: Solana.id,
      priorityTokenAddresses: "",
      wallet: {
        //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
        displayWalletEntry: false, //show wallet entry when connect particle.
        defaultWalletEntryPosition: WalletEntryPosition.TR, //wallet entry position
        uiMode: "dark", //optional: light or dark, if not set, the default is the same as web auth.
        supportChains: [Solana, Ethereum, EthereumGoerli], // optional: web wallet support chains.
        customStyle: {
          light: {
            colorAccent: "rgba(0,153,204,1)",
            colorPrimary: "rgba(220,237,243,1)",
            colorOnPrimary: "rgba(0,153,204,0.6)",
            primaryButtonBackgroundColors: [
              "rgba(255,255,255,1)",
              "rgba(255,255,255,1)",
            ],
            primaryButtonTextColor: "#ffffff",
            primaryIconButtonBackgroundColors: [
              "rgba(0,153,204,1)",
              "rgba(255,255,255,0.3)",
            ],
            cancelButtonBackgroundColor: "rgba(57,50,50,1)",
            backgroundColors: [
              "rgba(56,205,255,0.6)",
              [
                ["rgba(0,81,108,0.8)", "#ffffff00"],
                ["#ffffff00", "#ffffff00"],
              ],
            ],
            messageColors: ["rgba(0,153,204,1)", "rgba(0,81,108,1)"],
            borderGlowColors: ["rgba(0,153,204,0.6)", "rgba(255,255,255,1)"],
            modalMaskBackgroundColor: "rgba(56,205,255,0.74)",
            primaryIconTextColor: "rgba(255,255,255,1)",
          },
          dark: {
            colorAccent: "rgba(0,153,204,0.99)",
            colorPrimary: "rgba(15,95,121,0.5)",
            colorOnPrimary: "rgba(0,19,38,0.86)",
            primaryButtonBackgroundColors: [
              "rgba(0,153,204,1)",
              "rgba(255,255,255,1)",
            ],
            primaryIconButtonBackgroundColors: [
              "rgba(0,153,204,1)",
              "rgba(255,255,255,1)",
            ],
            primaryButtonTextColor: "rgba(69,73,115,1)",
            cancelButtonBackgroundColor: "#666666",
            backgroundColors: [
              "rgba(0,19,38,1)",
              [
                ["rgba(0,153,204,0.4)", "#e6b1f700"],
                ["#7dd5f94d", "#7dd5f900"],
              ],
            ],
            messageColors: ["#7DD5F9", "#ed5d51"],
            borderGlowColors: ["#7bd5f940", "#323233"],
            modalMaskBackgroundColor: "#141430b3",
            primaryIconTextColor: "rgba(255,255,255,1)",
            primaryTextColor: "rgba(255,255,255,1)",
          },
        }, //optional: custom wallet style
      },
      securityAccount: {
        //optional: particle security account config
        //prompt set payment password. 0: None, 1: Once(default), 2: Always
        promptSettingWhenSign: 1,
        //prompt set master password. 0: None(default), 1: Once, 2: Always
        promptMasterPasswordSettingWhenLogin: 1,
      },
    });

    //check user logged  INKOE SUSCRBIE
    const isLogin = particle.auth.isLogin();
    if (!isLogin && invokeSuscribe) {
      // Connect Kit
      var connectKit = new ParticleConnect({
        projectId: "728abe3c-03d6-48e8-a322-c58e0f57dfd7",
        clientKey: "cFw2Gvfv24oXH6ka5Y4JU7crp9Fmuv46fcqvHd9k",
        appId: "bcf1f843-891b-4e66-a1b0-3c367f6314a3",
        chains: [Solana],
        particleWalletEntry: {
          //optional: particle wallet config
          displayWalletEntry: false, //display wallet button when connect particle success.
          defaultWalletEntryPosition: WalletEntryPosition.TR,
          supportChains: [Solana],
          customStyle: {}, //optional: custom wallet style
        },
        wallets: [
          ...solanaWallets(),
          ...evmWallets({
            projectId: "120426b8ecb186feca9867d93a017036",
            showQrModal: false,
          }),
        ],
        walletSort: ["Particle Auth", "Wallet"], //optional：walelt order
        particleAuthSort: [
          //optional：display particle auth items and order
          "email",
          "google",
          "discord",
          "phone",
          "phantom"
        ],
      });
      var options = {
        //preferredAuthType : 'google',
        // account : 'particle',
        theme: "dark",
        preferredNetwork: "solana",
        // showQrModal: false,
        //  showWalletModal: false,
        //  showSwitchChainModal: false,
        //  showDisconnectModal: true,
        //   showAccountModal: true,
      };

      connectKit.connect("particle", options);
    }

    //console.log("isLogin", isLogin);

    // Listeners
    particle.auth.on("connect", (userInfo) => {
      console.log("particle userInfo", userInfo);
      if (userInfo) {
        for (let index = 0; index < userInfo.wallets.length; index++) {
          const element = userInfo.wallets[index];
          if (element.chain_name == "solana") {
            console.log("element.public_address.toString()", element.public_address.toString())
            return element.public_address.toString();
            sendMessage("CryptoManager", "ParticleConnection", element.public_address.toString() );
          }
        }

        //setProvider(particle);
        
      }
    });

    const particleProvider = new ParticleProvider(particle.auth);
    //console.log(particleProvider.PublicKey, "particleProvider---")

    const account = await particle.solana.getAddress();

    //if you need support solana chain
    const solanaWallet = new SolanaWallet(particle.auth);

    const publicKey = solanaWallet.publicKey;
    //console.log(publicKey,"publicKey"   )
    walletParticle.current = solanaWallet;

    setProvider(particle);
    console.log(providerW,"providerW")

    // Popup size

    if(openBuy){
      
      console.log("Open buy")
      var width = 400;
      var height = 570;

      // Calculate the position to center the popup
      var left = (window.screen.width / 2) - (width / 2);
      var top = (window.screen.height / 2) - (height / 2);

      // Features of the popup
      console.log(publicKey,"publicKey solana")
      var features = 'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
      particle.openBuy( {newtork:'Solana',walletAddress:publicKey}, "PopupWindow", features);
    }
    

    // console.log(solanaWallet,"solanawallet",account, particle.solana)
    if (account) {
     // console.log("Creando iframe");
      // Invoke Iframe Wallet
      if(!openBuy){
      const url = particle.buildWalletUrl({
        //optional: left top menu style, close or fullscreen
        //"fullscreen": wallet will be fullscreen when user click.
        //"close": developer need handle click event
        topMenuType: "close",
      });

      setSrc(url);
      setIframeDisplay("block");
    }
      //const iframe = document.createElement("iframe");
      //iframe.src = url;
      //if topMenuType is "close"
      window.addEventListener("message", (event) => {
        if (event.data === "PARTICLE_WALLET_CLOSE_IFRAME") {
          setIframeDisplay("none");
          //console.log("Frame close");
        }
      });

      //particle.openWallet(iframe);
      console.log("account solna",account.toString())
      return account.toString();
    }


   
  

    /*particle.auth.logout().then(() => {
        console.log("logout");
        });*/
  };

  //Swindow.web3.currentProvider.isParticleNetwork // => true

  /*
     


    }

   /* useEffect(() => {
        async function chainChanged(Chain) {
            console.log('DEMO-onChainChanged：', Chain);
        }
        if (connectKit) {
          
        }
    }, [connectKit]);*/

  /*const connectKit2 = useParticleConnect()
    useEffect(() => {
        if (account) {
            
            const userInfo = connectKit2.particle.getUserInfo();

            console.Log("userInfo", userInfo)
            
            sendMessage("CryptoManager", "ParticleConnection",account.toString() );
            console.log(account.toString());
        }
    }, [account]);

   /* const LogRenderChains = useCallback(() => {
        console.log('isSwtichChain:', isSwtichChain);
        console.log('renderChains:', renderChains);
    }, [renderChains, isSwtichChain]);*/

  //const pc = useParticleConnect();
  //pc.disconnect();
  //console.log('disconnected');

/*
  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };

      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );*/

  const tips = [
    "Remember to Enable Hardware Acceleration & Run in Background if you are using Chrome browser.",
    "Remember to disable brave Shields if you are using Brave browser.",
    "Loading time for first time player may take 1-5 minutes depending on player's internet speed and PC capabilities",
    "Bloomverse is the gamified fight for liberty, your support means a lot to the global fight for freedom! ",
    "$Bloomies can be used to mint new items and weapons, claim exclusive rights and get products from sponsors!",
    "Bloomverse is a self funded community initiative, this is only the begining!",
    "The Bloomverse Closed Beta will have countless guilds competing against each other and over a Million Dollars in Prizes ",
    "Bloomverse is the first game created to benefit your real life as you play, no more gamer remorse!",
    "Bloomverse drafting stage will allow you to pick competitive and strategic gear to have specific advantages in game, choose wisely!",
  ];

  const tipsImages = [
    "./chrome.jpg",
    "./shield.jpg",
     null,
     null,
     null,
     null,
     null,
     null,
     null,
  ];

  const [messageTip, setTip] = useState("Connecting to bloomverse...");
  const [imageTip, setImageTip] = useState(null);
  const tipTime = 8000; //ms

  const backgrounds = [
    "GameLoadingScreen.jpg",
    "GameLoadingScreen2.jpg"
  ]

  const cdnOrigin =  "/game/"; //"https://playbloomverse.nyc3.digitaloceanspaces.com/game/"; //"/game/"; // 
  const cdnEndPoint = "https://playbloomverse.nyc3.cdn.digitaloceanspaces.com";
  const cdnSubdomain = "https://cdn1.bloomverse.io/game/"//"https://cdn1.bloomverse.io";
  const apiURL = "https://api.bloomverse.io/"; // "http://localhost:8103/";


  const {unityProvider, unityContext, takeScreenshot,  loadingProgression, isLoaded,
    unload,
    sendMessage,
    addEventListener,
    removeEventListener,} = useUnityContext({
    loaderUrl: cdnOrigin + "Build/game.loader.js",
    dataUrl: cdnOrigin + "Build/game.data",
    frameworkUrl: cdnOrigin + "Build/game.framework.js",
    codeUrl: cdnOrigin + "Build/game.wasm",
    streamingAssetsUrl: cdnOrigin + "StreamingAssets",
    productName: "Bloomverse",
    productVersion: "1.6.5",
    companyName: "Bloomverse Studios",
    webGLContextAttributes: {
      alpha: false
    },
  });
  


  window.unityGame = unityContext;



  function changeMsg() {
    console.log("Changing mesg",currentTip);
    if (first) {
      setTip(tips[0]);
    } else {
      //setTip(tips[randomNumberInRange(0, tips.length - 1)]);
      setTip(tips[currentTip.current]);
      setImageTip(tipsImages[currentTip.current])
      currentTip.current = currentTip.current + 1;
      if(currentTip.current==tips.length){
        currentTip.current = 0;
      }
    }
  }

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleClickMessage() {
    sendMessage("CryptoManager", "PrintMessage", "ASDF");
  }

  const handleClickGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      //console.log(tokenResponse);
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=` +
          tokenResponse.access_token
      );
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${tokenResponse.access_token}`
      );
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300)
          sendMessage(
            "CryptoManager",
            "OnGoogleLogin",
            JSON.parse(this.responseText).email
          );
      };
      xhr.send();
    },
    onError: () => {
      //console.log("Login Failed");
      sendMessage("CryptoManager", "OnGoogleLoginError", "login Error");
    },
  });

  const handleOpenURL = useCallback(
    async (url) => {
      //console.log(url, "handleOPENURLREACT");
      window.open(url, "_blank");
    },
    [sendMessage]
  );

  const handlePasteText = useCallback(
    async (pastedText) => {
      sendMessage("UIRPMload", "GetPastedText", pastedText);
    },
    [sendMessage]
  );

  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  const handleRPM = useCallback(
    async (partner) => {
      var subdomain = partner;
      //console.log(partner);
      setRPMready(true);
      var rpmFrame = document.getElementById("rpm-frame");
      var rpmContainer = document.getElementById("rpm-container");
      rpmFrame.src = `https://${
        subdomain != "" ? subdomain : "demo"
      }.readyplayer.me/avatar?frameApi`;

      window.addEventListener("message", subscribe);
      document.addEventListener("message", subscribe);
      function subscribe(event) {
        const json = parse(event);
        //console.log(event.data, "json------------");
        if (event.data != null) {
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(event.data)[1];
          //console.log(ext, "ext --------------------");
          if (ext == "glb") {
            sendMessage("UIRPMload", "loadAv", event.data);
            /*window.unityGame.SendMessage(
                        "UIRPMload", //"WebAvatarLoader", // Target GameObject name
                        "loadAv", // Name of function to run
                        event.data
                    );*/

            rpmContainer.style.display = "none";
            setRPMready(false);
            // Send message to a Gameobject in the current scene
          }
        }

        //console.log(event, "eventName----------------");
        if (
          window.unityGame == null ||
          json?.source !== "readyplayerme" ||
          json?.eventName == null
        ) {
          return;
        }
        // Send web event names to Unity can be useful for debugging. Can safely be removed

        window.unityGame.SendMessage(
          "DebugPanel",
          "LogMessage",
          `Event: ${json.eventName}`
        );

        // Subscribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === "v1.frame.ready") {
          rpmFrame.contentWindow.postMessage(
            JSON.stringify({
              target: "readyplayerme",
              type: "subscribe",
              eventName: "v1.**",
            }),
            "*"
          );
        }

        // Get avatar GLB URL
        if (json.eventName === "v1.avatar.exported") {
          rpmContainer.style.display = "none";
          setRPMready(false);
          // Send message to a Gameobject in the current scene
          window.unityGame.SendMessage(
            "UIRPMload", //"WebAvatarLoader", // Target GameObject name
            "loadAv", // Name of function to run
            json.data.url
          );
          //console.log(`Avatar URL: ${json.data.url}`);
        }

        // Get user id
        if (json.eventName === "v1.user.set") {
          //console.log(
           // `User with id ${json.data.id} set: ${JSON.stringify(json)}`
          //);
        }
      }
    },
    [sendMessage]
  );

  // Handle Particle Click
  const handleClickParticle = useCallback(async () => {
    //console.log("Connect to Particle");

    // console.log(provider)
    try {
      var particleRes = await initParticleCore(true);
      sendMessage("CryptoManager", "OnPhantom", particleRes);
      /*
        if (account) {
            // Invoke wallet connect
            console.log(connectKit)
            connectKit.particle.openWallet();
              
        }else{
            // Invoke wallet connect
            //connectKit.openWalletConnect();
            //connectModal.openConnectModal();

        }*/
    } catch (e) {
      alert(
        "No phantom wallet detected please install the browser extension" + e
      );
    }
  }, [sendMessage]);

  const hideRpm = function () {
    let rpmContainer = document.getElementById("rpm-container");
    rpmContainer.style.display = "none";
  };

  // Phantom
  const handleAskTransaction = useCallback(
    async (amount, tokenId, tokenName) => {
      const TOKEN = "C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue"; //'8qiGgayKGDfpt3VG6oa47qnzF4Ju3K12Ez2C6vMzsdTk' bloomie
      const WALLET_SERVER = "DiF1nzdqxdK49svrLztBxkSdAQ1PJwxs9mYiaP5okWQq";

      //console.log(amount, tokenId, tokenName, "data");

      // Construct wallet keypairs
      const fromWallet = getProvider();

      //console.log(fromWallet, "fromWallet.publicKey");
      // Connect to cluster
      const connection = new Connection(
        "https://magical-bitter-sanctuary.solana-mainnet.discover.quiknode.pro/e1b4afe48cc598fe882dc1dcac44225212e93c6a/",
        "confirmed"
      );
      //console.log("Endpoint: ", connection.rpcEndpoint);

      // let blockhash = (await connection.getLatestBlockhash("finalized")).blockhash
      //  console.log("recentBlockhash: ", blockhash);
      // console.log('Endpoint: ', connection.rpcEndpoint)

      //let blockhash = await connection.getRecentBlockhash('finalized').blockhash;

      await fromWallet.connect();
      // console.log('From Wallet: ', fromWallet.publicKey)

      // Create new token mint
      const mint = await getMint(connection, new PublicKey(TOKEN));
      // console.log('Mint: ', mint.address)

      const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        fromWallet,
        new PublicKey(TOKEN),
        fromWallet.publicKey
      );
      //console.log("Token From: ", fromTokenAccount.address);

      const toTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        fromWallet,
        new PublicKey(TOKEN),
        new PublicKey(WALLET_SERVER)
      );
      //console.log("Token To:", toTokenAccount.address);

      const instructions = [];

      instructions.push(
        createTransferInstruction(
          fromTokenAccount.address,
          toTokenAccount.address,
          fromWallet.publicKey,
          0.1 * 1000000
        )
      );

      const transaction = new Transaction().add(...instructions);
      transaction.feePayer = fromWallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getRecentBlockhash()
      ).blockhash;

      /*- const transactionSignature = await connection.sendRawTransaction(
          transaction.serialize(),
          { skipPreflight: true }
        );*/
      try {
        const { signature } = await fromWallet.signAndSendTransaction(
          transaction
        );
        //console.log(signature + "sginature");
        sendMessage("CryptoManager", "OnTokenTransaction", signature);
      } catch (e) {
        //console.log(e + " error ");
        sendMessage("CryptoManager", "OnTokenTransaction", "PhantomError");
      }

      //
    },
    [sendMessage]
  );

  // Particle Payment --------------------------------------------------------------
  const handleAskTransactionParticle = useCallback(
    async (amount, tokenId, tokenName) => {
      console.log(amount,tokenId,tokenName);
      const TOKEN =  tokenId //"C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue"; //'8qiGgayKGDfpt3VG6oa47qnzF4Ju3K12Ez2C6vMzsdTk' bloomie
      const WALLET_SERVER = "DiF1nzdqxdK49svrLztBxkSdAQ1PJwxs9mYiaP5okWQq";
      // Construct wallet keypairs
      const fromWallet = walletParticle.current; // Returns   window.particle?.solana;
      // Connect to cluster
      const connection = new Connection(
        "https://magical-bitter-sanctuary.solana-mainnet.discover.quiknode.pro/e1b4afe48cc598fe882dc1dcac44225212e93c6a/",
        "confirmed"
      );

      let mint = await connection.getParsedAccountInfo(
        new PublicKey(TOKEN)
    )
      
    let tokenDecimals = parseInt(mint.value.data.parsed.info.decimals);
    console.log(mint.value.data.parsed.info, "decimals");
    

      //var fpublicKey = await fromWallet.getAddress()
      var pPublicKey = fromWallet.publicKey;

      //console.log(walletParticle.current.publicKey, "fromWallet.publicKey--");
      let fromTokenAccount = null;
try{
   fromTokenAccount = await getOrCreateAssociatedTokenAccount(
    connection,
    fromWallet,
    new PublicKey(TOKEN),
    pPublicKey
  );
}catch(e){
  console.log(e);
}
  
    let toTokenAccount= null;
    try{
      toTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        fromWallet,
        new PublicKey(TOKEN),
        new PublicKey(WALLET_SERVER)
      );

      }catch(e) { console.log(e)}

let divider=   Math.pow(10,tokenDecimals);


      const instructions = [];
      try{
        instructions.push(
          createTransferInstruction(
            fromTokenAccount.address,
            toTokenAccount.address,
            pPublicKey,
            amount * divider
          )
        );
      }catch(e) { console.log(e)}
      

      const transaction = new Transaction().add(...instructions);
      transaction.feePayer = pPublicKey;
      transaction.recentBlockhash = (
        await connection.getRecentBlockhash()
      ).blockhash;

      try {
        const result = await fromWallet.signAndSendTransaction(transaction);
        //console.log(result + "sginature");
        sendMessage("CryptoManager", "OnTokenTransaction", result);
      } catch (e) {
        //console.log(e + " error ");
        sendMessage("CryptoManager", "OnTokenTransaction", "PhantomError");
      }
    },
    [sendMessage]
  );


  // Buy solana 
  // 
  const handleAskTransactionSolana = useCallback(

    
    async (solPrice,bloomiesAmount,userWallet) => {

     
      if(walletParticle.current==null){
        var particleRes = await initParticleCore(true);
        sendMessage("CryptoManager", "OnSolanaTransaction", "PhantomError");
       
      }else{

      console.log(solPrice,"solprice",bloomiesAmount,userWallet);
      const WALLET_SERVER = "DiF1nzdqxdK49svrLztBxkSdAQ1PJwxs9mYiaP5okWQq"; // Deposit Wallet
 
      const fromWallet = walletParticle.current;
      const connection = new Connection(
        "https://magical-bitter-sanctuary.solana-mainnet.discover.quiknode.pro/e1b4afe48cc598fe882dc1dcac44225212e93c6a/",
        "confirmed"
      );
  
      const pPublicKey = await fromWallet.publicKey;
  
      const transferInstruction = SystemProgram.transfer({
        fromPubkey: pPublicKey,
        toPubkey: new PublicKey(WALLET_SERVER),
        lamports: parseInt(parseFloat(solPrice) * web3.LAMPORTS_PER_SOL) // Convert SOL to lamports
      });
      
      const transaction = new Transaction().add(transferInstruction);
      transaction.feePayer = pPublicKey;
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.recentBlockhash = blockhash;
  
      try {
        
        const result = await fromWallet.signAndSendTransaction(transaction);

        // SEND BLOOMIES TO USER
        var data = {
          walletId : userWallet,
          amount : bloomiesAmount,
        }
        const xhr = new XMLHttpRequest();
          xhr.open(
            "POST",
            apiURL + "crypto/send_bloomiesSold"
          );
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.onload = function () {
            if (this.status >= 200 && this.status < 300)

            // rESPONSE FOR UNITY
            sendMessage("CryptoManager", "OnSolanaTransaction", result);
          };
          xhr.send(JSON.stringify(data));

        //sendMessage("CryptoManager", "OnSolanaTransaction", result);
      } catch (e) {
        sendMessage("CryptoManager", "OnSolanaTransaction", "PhantomError");
      }
      }
    },
    [sendMessage] // Ensure sendMessage is a stable reference
  );

  const handleClickPhantom = useCallback(async () => {
    //console.log("Connect to Phantom");

    // console.log(provider)
    try {
           let provider = getProvider()
       let connection = await provider.connect()
       let pubkey = await provider.publicKey
       console.log(pubkey.toString())
        setPublicKey(pubkey.toString())
        sendMessage("CryptoManager", "OnPhantom", pubkey.toString())
    } catch (e) {
      alert("No phantom wallet detected please install the browser extension");
    }
  }, [sendMessage]);

  const checkIfPhantom = useCallback(async () => {
    //console.log("Unity check if phantom");

    let provider = getProvider();

    if (provider) {
      //setProvider(provider)
      //sendMessage("CryptoManager", "IsPhantom")
    }
  }, [sendMessage]);

  const checkIfParticle = useCallback(async () => {
    console.log("Checking if particle", walletParticle.current!=null,walletParticle.current);
    if (walletParticle.current!=null) {
      console.log("sending connected");
      sendMessage("CryptoManager", "IsParticle","Connected")
    }else{
      console.log("sending not connected");
      sendMessage("CryptoManager", "IsParticle","notConnected")
    }
  }, [sendMessage]);


  const handleClickTokenMarket = useCallback(async () => {
     initTransak();
  }, [sendMessage]);

  // useEffect(() => {
  //     setTimeout(() => {
  //         let provider = getProvider()

  //         setProvider(provider)
  //     }, 2000);
  // })

  // useEffect(() => {
  //     addEventListener("CheckIfPhantom", checkIfPhantom)
  //     return () => {
  //         removeEventListener("CheckIfPhantom", checkIfPhantom)
  //     }
  // }, [addEventListener, removeEventListener, checkIfPhantom])

  function checkParamInURL(param) {
    // Assuming you're using window.location.search to get the query string
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has(param); // This will return true if the param exists, false otherwise
  }

  useEffect(() => {
    if ((!first & !maintenance) || checkParamInURL("maint") ) {

      var inter =  setInterval(changeMsg, tipTime);
      setTipInterval(inter);
      console.log("Setting back","url('./game/TemplateData/" + backgrounds[randomNumberInRange(0, backgrounds.length - 1)] + "')",backgrounds[randomNumberInRange(0, backgrounds.length - 1)])
      setBackground(backgrounds[randomNumberInRange(0, backgrounds.length - 1)]);
      setFirst(true);
    
      
    }else{

       setBackground("back-bloom.jpg");
    }
  }, []);

  useEffect(() => {
    //console.log("is loadade");

    // here to activate particle

    setFirst(false);
    clearInterval(tipInterval);
  }, [isLoaded]);

  useEffect(() => {
    // let provider = getProvider()

    // if (provider){
    //     setProvider(provider)
    //     sendMessage("GameController", "IsPhantom")
    // }

    //console.log("adding listeners");

    addEventListener("ClickPhantom", handleClickPhantom);
    addEventListener("TakeScreenshot", _takeScreenshot);
    addEventListener("ClickParticle", handleClickParticle);
    addEventListener("TokenMarket", handleClickTokenMarket);
    addEventListener("CheckIfPhantom", checkIfPhantom);
    addEventListener("CheckIfParticle", checkIfParticle);
    addEventListener("bloomieTransaction", handleAskTransactionParticle);
    addEventListener("solanaTransaction", handleAskTransactionSolana);
    addEventListener("ClickGoogle", handleClickGoogle);
    addEventListener("OpenURL", handleOpenURL);
    addEventListener("PasteText", handlePasteText);
    addEventListener("setupRpmFrame", handleRPM);

    //initParticleCore(false);

    return () => {
      removeEventListener("TakeScreenshot", _takeScreenshot);
      removeEventListener("ClickPhantom", handleClickPhantom);
      removeEventListener("ClickParticle", handleClickParticle);
      removeEventListener("CheckIfPhantom", checkIfPhantom);
      removeEventListener("CheckIfParticle", checkIfParticle);
      removeEventListener("TokenMarket", handleClickTokenMarket);
      removeEventListener("bloomieTransaction", handleAskTransactionParticle);
      removeEventListener("solanaTransaction", handleAskTransactionSolana);
      removeEventListener("ClickGoogle", handleClickGoogle);
      removeEventListener("OpenURL", handleOpenURL);
      removeEventListener("PasteText", handlePasteText);
      removeEventListener("setupRpmFrame", handleRPM);
    };
  }, [
    addEventListener,
    removeEventListener,
    _takeScreenshot,
    handleClickPhantom,
    checkIfPhantom,
    checkIfParticle,
    handleClickTokenMarket,
    handleClickGoogle,
    handleOpenURL,
    handlePasteText,
    handleRPM,
    handleClickParticle,
    handleAskTransactionSolana,
    handleAskTransactionParticle
  ]);

  return (

    

    <div
      className="App"
      style={{
        background: "url('/game/TemplateData/" + backgroundR + "') center center / cover",
        backgroundSize: 'cover',
        height: "100vh",
        
      }}
    >
      {/*    <div onClick={initTransak}>
      <a>HOLA MORROS</a>
    </div>
    */}
      {!maintenance ||  checkParamInURL("maint") ? (
        <div className={styles.container}>

       
        <div className={styles.unityWrapper}>
          {isLoaded === false && (
            
            <div>
               <div style={{position:'fixed',height:'40px',top:0,backgroundColor:'rgb(40 173 255 / 63%)',width:'100%',fontSize:'18px',fontWeight:'bold',color:'#fff',left:'0px',lineHeight:'40px'}}>
        May experience long loading times based on your PC download speed, sit back and relax! 
        </div>
              {/* <video style={{marginTop:100}} width="320" height="240" autoPlay='' loop muted>
                  <source src="./loadingvideo.mp4" type="video/mp4" />
                </video>
           */}
           
              <div
                style={{
                  position: "absolute",
                  bottom: 120,
                  textAlign: "center",
                  left: 0,
                  width: "100%",
                  paddingRight: '40px',
                  paddingLeft : '40px',
                  color: "#fff",
                  backgroundColor: "#33648199",
                  padding: "10px",
                  fontSize:'24px'
                }}
              >
                {messageTip}
              </div>
          
              <img
                src="./game/TemplateData/RefreshingIcon.png"
                style={{
                  width: "30px",
                  position: "absolute",
                  bottom: "70px",
                  marginLeft: "-15px",
                }}
                className={styles.rotate}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  textAlign: "center",
                  left: 0,
                  width: "100%",
                  color: "#fff",
                  padding: "0px",
                  fontSize: '18px'
                }}
              >
                {Number(loadingProgression * 100).toFixed(1) + "%"} 
              </div> 
              <div className={styles.loadingBar}>
                <div
                  className={styles.loadingBarFill}
                  style={{ width: loadingProgression * 330 }}
                />
              
                <div
                />
                
              </div>

              <div style={{position:"absolute",left:'50%', bottom:'0px'}}>
              {imageTip!=null ? (
                 <div style={{position:"absolute",
                 bottom:'220px',
                 backgroundImage:`url(${imageTip})`,
                 backgroundSize:"cover",
                 width:"600px",
                 height: "240px",
                 left:'-300px'
                ,boxShadow: '#00000047 10px 5px 5px'}}>
                </div>
              ) : ""}

            </div>

            </div>
          )}

          <div id={"rpm-container"} className={styles["rpm-container"]}>
            <iframe
              id="rpm-frame"
              className={styles["rpm-frame"]}
              allow="camera *; microphone *"
              style={{ pointerEvents: RPMready ? "initial" : "none" }}
            ></iframe>
            <button
              id="rpm-hide-button"
              className={styles["rpm-hide-button"]}
              onClick={hideRpm}
            >
              Hide
            </button>
          </div>

        


          <Unity
            style={{
              width: "100%",
              height: "100vh",
              display: isLoaded ? "block" : "none",
            }}
            unityProvider={unityProvider}
            /*devicePixelRatio={devicePixelRatio}*/
          />

{/*
<button onClick={_takeScreenshot}>Take Screenshot</button> */}

          <iframe
            allowtransparency="true"
            style={{
              position: "fixed",
              top: "100px",
              right: "41px",
              borderRadius: "30px",
              border: "none",
              display: iframedisplay,
            }}
            src={setsrc}
            width={400}
            height={600}
          ></iframe>
          {/* <button onClick={handleClickMessage}>Send message</button>
            <button onClick={handleAskTransaction}>Ask transaction</button>
    */}
        </div>
      </div>

      ) : (<Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
            backgroundColor: 'transparent',  // Update with your desired background color
        }}
    >


    <Grid container spacing={2} maxWidth ={'lg'}>
    <Grid item xs={12}>
        <img src={logo} style={{height:"120px"}} />
    </Grid>
    <Grid container>
       
        <Grid style={{textAlign:"center"}} item  md={12} >
              <video
              poster={poster1}
              onClick={animtease}
              ref = { anim1Ref }      
                loop
                autoPlay
                controls
                playsInline="true" disablePictureInPicture="true"
                style={{
                  backgroundColor: 'rgb(1 2 3 / 62%)',
                  cursor:'pointer',
                  position:'relative',
                  marginRight:'0%',
                  top: 0,
                  textAlign:'center',
                  maxWidth: '100%',
                  padding:20,
                  height: '290px',
                  borderRadius: '8px',  
                  zIndex : 1000,
                }}  >  <source src= { video1 } type="video/mp4" />
            </video>  
        </Grid>
        <Grid style={{color:'#fff', textAlign:"center", width:"100%"}} md={12} >
            <img style={{height:90,textAlign:'center'}} src={upgradingImg} />
            <ul style={{listStyle:"none"}}>
              <li>Network optimization for upcoming Tournaments</li>
              <li>Migrating to WebGPU</li>
              <li>Enhanced UI</li>
              <li>Fixing identified bugs and issues</li>
              <li><a href="https://discord.gg/fmDWvnZaHh" target="_blank"><img src={discord} style={{height:50,marginTop:"40px",textAlign:"center",cursor:"pointer"}} /></a></li>
              </ul>
        </Grid>
    </Grid>
    </Grid>
  
      </Box>
      )}
      
    </div>
  );
};

export default Play;
